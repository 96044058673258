body {
  font-family: 'Cabin', sans-serif;
}

.page {
  background-color: #E3E6E6;
  width: 100%;
  float: none;
  position: absolute;
  box-shadow: none;
  overflow: hidden;
  min-height: 100%;
}

.content-sec {
  height: 100%;
  width: 100%;
  /* max-width: 1480px; */
  margin: 0 auto;
  overflow: hidden;
  background-color: #fff;
}

/*- contact us page-*/

.contact-us .site-pages.contact-us {
  background-image: url(../images/contact.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 275px;
  width: 100%;
  display: flex;
  align-items: end;
  position: relative;
}

.site-pages .sec-bottom {
  position: absolute;
  margin: 10px 50px;
  bottom: 50px;
}

.site-pages .sec-bottom .top-title {
  color: #fff;
  font-size: 14px;
  letter-spacing: 4px;
  font-weight: 500;
  text-align: left;
  text-transform: uppercase;
}

.site-pages .sec-bottom .sub-title {
  color: #fff;
  font-size: 40px;
  letter-spacing: 0px;
  font-weight: 600;
  text-align: left;
  text-transform: capitalize;
  line-height: 55px;
  margin: 0;
}

.contact-us #contactform {
  background: transparent linear-gradient(180deg, #E9F6FC 0%, #fff 100%);
}

.contact-us .form-section {
  padding: 50px;
}

.contact-us .form-section .contact-form {
  margin-top: -34px;
}

.contact-us .form-section .expert-box {
  margin-bottom: 40px;
}

.contact-us .expert-box h4 {
  color: #00AAE2;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  opacity: 1;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.contact-us .expert-box h4 img {
  height: 20px;
  margin-right: 10px;
}

.contact-us .expert-box p {
  color: #152238;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  margin-bottom: 10px;
  margin-right: 10px;
  opacity: 1;
}

.contact-us .form-section .expert-box .expert-phn p {
  margin-right: 10px;
  letter-spacing: 0px;
  color: #152238;
  opacity: 1;
  font-size: 16px;
  font-weight: 500;
  padding-right: 10px;
  margin-bottom: 4px;
}

.contact-us .form-section .expert-box p.date {
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.5;
  font-size: 14px;
  font-weight: 400;
}

.contact-us .form-section .contact-form .form-check input {
  height: 16px;
  width: 16px;
  accent-color: #26477F;
}

.agree-check {
  display: flex;
  align-items: baseline;
}

.contact-us .form-section .contact-form .form-check .form-error {
  margin-left: -20px;
}

.contact-us .form-section .contact-form .form-check label {
  margin-left: 10px;
}

.contact-us .form-section .expert-box.letus-know h4 {
  font-size: 18px;
  font-weight: 600;
}

.did-floating-label-content {
  position: relative;
  margin-top: 20px;
  margin-bottom: 10px;
}

.did-floating-label {
  color: #152238;
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 11px;
  padding: 0;
  background: transparent;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.did-floating-input {
  font-size: 14px;
  display: block;
  width: 100%;
  height: 36px;
  padding: 0;
  background: transparent;
  color: #323840;
  border: none;
  border-bottom: 1px solid #000;
  border-radius: 0;
  box-sizing: border-box;
  box-shadow: none;
  outline: none;

}

.did-floating-input:focus {
  outline: none;

}

.did-floating-input:focus~.did-floating-label {
  top: -15px;
  font-size: 14px;
  color: #152238 !important;
}

.did-floating-input:not(:placeholder-shown)~.did-floating-label {
  top: -15px;
  font-size: 14px;
  color: #152238 !important;
}

.PhoneInput {
  border-bottom: 1px solid #182236;
}

.PhoneInput .PhoneInputInput {
  background: transparent;
  border: none;
  border-radius: 0;
  box-shadow: none;
  color: #000;
  font-size: 15px;
  font-weight: 500;
  outline: none;
  padding: 0;
  text-align: left;
  text-transform: inherit;
  margin-top: 12px;
}

.PhoneInput .PhoneInputInput::placeholder {
  color: #152238;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.PhoneInput .PhoneInputCountryIcon--border {
  box-shadow: none;
  margin-top: 10px;
}

.PhoneInput .PhoneInputCountryIcon svg {
  margin-top: 8px;
}

.contact-us .form-section .contact-form .submit-btn {
  background: #01A0C6;
  border: none;
  border-radius: 4px;
  box-shadow: none;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  margin-top: 20px;
  opacity: 1;
  outline: none;
  padding: 10px 15px;
  text-align: center;
  float: right;
}

.contact-us .locations {
  padding: 10px 0px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-us .locations .loc-box {
  padding: 20px 30px;
  background: transparent linear-gradient(180deg, #131669 0%, #00172D 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  filter: drop-shadow(2px 3px 4px #00000033);
  border-radius: 10px;
  margin-bottom: 25px;
  min-height: 100%;
}

.contact-us .locations .loc-box img {
  height: 45px;
  margin-bottom: 8px;
}

.contact-us .locations .loc-box h2 {
  color: #FFFFFF;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0;
}

.contact-us .locations .loc-box p {
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0;
  margin-bottom: 5px;
  line-height: 28px;
}

/*- about us page-*/

.about-us .site-pages {
  background-image: url(../images/about.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 275px;
  width: 100%;
  display: flex;
  align-items: end;
  position: relative;
}

.about-us .vision-mission {
  padding: 50px;
  background: transparent linear-gradient(180deg, #E9F6FC 0%, #fff 100%);
}

.about-us .vision-box {
  display: flex;
  margin-bottom: 60px;
}

.about-us .vision-box img {
  height: 60px;
}

.about-us .vision-box h2 {
  color: #00172D;
  font-size: 22px;
  font-weight: 600;
  text-align: left;
  text-transform: capitalize;
}

.about-us .vision-box .vision-text {
  padding: 0 40px;
  max-width: 450px;
}

.about-us .vision-box .vision-text p {
  color: #00172D;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  text-transform: inherit;
  line-height: 28px;
}

.about-us .right-side {
  margin-top: 100px;
}

.about-us .about-teams {
  padding: 50px;
}

.about-us .about-teams .sec-top-title {
  color: #43C2EC;
  letter-spacing: 4px;
  font-weight: 400;
  font-size: 17px;
  text-align: left;
  text-transform: uppercase;
}

.about-us .about-teams .designations {
  margin-top: 60px;
}

.about-us .about-teams .designations .nav-item .nav-link.active {
  background-color: transparent;
  color: #02AABD;
}

.about-us .about-teams .designations .nav-item .nav-link {
  color: #000000;
  background-color: transparent;
  font-size: 22px;
  font-weight: 600;
  text-transform: capitalize;
  padding-left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.about-us .about-teams .designations .nav-item .nav-link:focus {
  outline: none;
  box-shadow: none;
}

.about-us .about-teams .designations .nav-item .nav-link svg {
  float: right;
  font-size: 18px;
}

.about-us .about-teams .designations .nav-item .nav-link.nav-link.active svg path {
  d: path('m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z')
}

.about-us .about-teams .designations .designation-descript {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.about-us .about-teams .designations .designation-descript .left-desc {
  max-width: 480px;
}

.about-us .about-teams .designations .designation-descript .total-employee {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-us .about-teams .designations .designation-descript .total-employee p {
  color: #02AABD;
  font-weight: 600;
  font-size: 150px;
  text-align: left;

}

.about-us .about-teams .designations .designation-descript h3 {
  font-size: 22px;
  font-weight: 500;
  text-transform: capitalize;
  color: #02AABD;
  margin-top: 0;
}

.about-us .about-teams .designations .tab-content>.active.show {
  opacity: 1 !important;
}

.about-us .about-teams .designations .designation-descript hr {
  border-color: #02AABD;
  border-width: 2px;
  max-width: 80px;
  margin-top: 0;
  margin-left: 0;
}

.about-us .about-teams .designations .designation-descript p {
  color: #000000;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}

.about-us .designations.mobile-view {
  display: none;
}

.about-us .about-teams .sec-bottom h2 {
  color: #0052A2;
  font-size: 45px;
  font-weight: 600;
  text-align: left;
  text-transform: inherit;
  line-height: 58px;
  margin-top: 10px;
}

.about-us .about-teams .sec-bottom p {
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  text-transform: inherit;
  text-align: justify;
  padding-right: 90px;
  line-height: 28px;
}

.about-us .about-teams .person-box .person-title h2 {
  color: #00AAE2;
  font-size: 15px;
  font-weight: 600;
  text-transform: inherit;
  text-align: center;
}

.about-us .about-teams .person-box .person-title p {
  color: #152238;
  font-size: 15px;
  font-weight: 400;
  text-transform: inherit;
  text-align: center;
}

.about-us .about-teams .person-box {
  padding: 0 15px;
}

.about-us .about-teams .person-box .image-container {
  position: relative;
}

.about-us .about-teams .people {
  padding: 30px 0;
}

.about-us .about-teams .person-box img {
  height: auto !important;
}

.about-us .about-teams .person-box .image-container .after {
  position: absolute;
  top: 0;
  left: 0px;
  width: 100%;
  height: 100%;
  display: none;
  color: #FFF;
}

.about-us .about-teams .person-box .image-container .after .img-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.about-us .about-teams .person-box .image-container .after img {
  height: 45px !important;
  padding: 5px;
  border-radius: 50%;
  background: rgb(0 0 0 / 36%);
  width: auto !important;
}

.about-us .about-teams .person-box:hover .after {
  display: block;
  background: rgb(0 0 0 / 36%);
  border-radius: 4px;
}

.about-us .about-teams .person-box .person-title {
  padding: 20px 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.about-us .about-teams .person-box.mentor .person-title {
  padding: 20px 8px;
  display: block;
}

.about-us .about-teams .person-box.mentor .person-title h2,
.about-us .about-teams .person-box.mentor .person-title p {
  text-align: left;
}

.about-us .about-teams .person-box.mentor .person-title h2 {
  letter-spacing: 4px;
}

.about-us .about-teams .person-box.mentor .person-title p {
  color: #000000;
  line-height: 28px;
}

.about-us .about-teams .person-box.mentor .mentor-title {
  color: #00172D !important;
  font-size: 17px;
  font-weight: 400;
  text-transform: inherit;
  text-align: left;
  letter-spacing: 5px;
  margin-bottom: 8px;
}

.about-us .about-teams .person-box.mentor {
  margin-top: 50px;
}

.about-us .about-teams .people {
  margin: 45px 0;
}

.about-us .about-teams .people img {
  width: 100%;
  object-fit: cover;
  height: 300px;
}

.about-us .about-teams .people.mobile-view {
  display: none;
}

.about-us .about-teams .people .bottom-team img {
  height: 250px !important;
}

.about-us .inspiring {
  height: 550px;
  width: 100%;
  position: relative;
}

.about-us .inspiring img {
  width: 100%;
  height: 100%;
}

.about-us .inspiring h2 {
  position: absolute;
  bottom: 0px;
  padding: 20px 64px;
  color: #fff;
  font-size: 45px;
  font-weight: 500;
  line-height: 50px;
}

.about-us .inspiring h2 span {
  font-size: 35px;
}

.about-us .excellence {
  background: transparent linear-gradient(360deg, #E9F6FC 0%, #FFFFFF 100%);
}

.about-us .about-teams.excellence .sec-bottom p {
  padding-right: 0 !important;
}

.about-us .excellence .qualities {
  margin: 42px 0;
  height: 100%;
  overflow: hidden;
}

.about-us .excellence .qualities .quality-inner {
  height: 180px;
  overflow-y: auto;
}

.about-us .excellence .qualities .quality-inner::-webkit-scrollbar {
  width: 0 !important
}

.about-us .excellence .qualities .quality-inner::-webkit-scrollbar {
  display: none;
}

.about-us .excellence .quality-text .title {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.about-us .excellence .quality-text .title h2 {
  color: #25A5D0;
  font-size: 22px;
  font-weight: 600;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 0;
}

.about-us .excellence .quality-text .title img {
  height: 30px;
  margin-right: 8px;
}

.about-us .excellence .quality-text p {
  color: #00172D;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  text-transform: inherit;
  line-height: 28px;
}

.about-us .excellence .news-events .react-multi-carousel-list ul .react-multi-carousel-item .carousel__item .carousel__caption p {
  letter-spacing: 0px;
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
  font-size: 18px;
  font-weight: 500;
  margin: 10px 0;
}

.about-us .excellence .news-events .react-multi-carousel-list ul .react-multi-carousel-item .carousel__item a {
  text-decoration: none;
}

.about-us .excellence .news-events .react-multi-carousel-list .react-multiple-carousel__arrow {
  background: #000000;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  z-index: 1;
}

.about-us .excellence .news-events .custom-dot {
  background: #D9EBF4;
  width: 100%;
  height: 6px;
  border-width: 0;
  border-radius: 2px;
}

.about-us .excellence .news-events .react-multi-carousel-dot-list li {
  width: 100%;
}

.about-us .excellence .news-events .custom-dot--active {
  background: #82C2E2;
  border-radius: 2px;
}

.about-us .excellence .news-events .react-multi-carousel-list .react-multi-carousel-track {
  padding-bottom: 40px;
}

.about-us .excellence .news-events .react-multi-carousel-list button:focus {
  outline: 1px dotted;
  outline: 0px auto -webkit-focus-ring-color;
  background: #82C2E2 !important;
  border-radius: 0;
}

.about-us .excellence .news-events {
  margin-top: 150px;
}

.about-us .excellence .news-events .sec-bottom {
  margin-bottom: 55px;
}

.about-us .excellence .news-events .sec-top-title,
.about-us .excellence .news-events .sec-bottom .sec-title {
  text-align: center;
}

.about-us .excellence .news-events .news-section {
  background-color: #fff;
  margin: 0 50px;
}

.about-us .excellence .news-events .react-multi-carousel-dot-list {
  margin: 0 50px;
}

.about-us .excellence .news-events .news-section img {
  width: 100%;
}

.about-us .excellence .news-events .news-section .bottom-news {
  padding: 15px;
  padding-bottom: 45px;
}

.about-us .excellence .news-events .news-section .bottom-news h5 {
  color: #43C2EC;
  font-weight: 500;
  font-size: 17px;
  text-align: left;
  text-transform: capitalize;
  line-height: 22px;
  margin-bottom: 15px;
}

.about-us .excellence .news-events .news-section .bottom-news p.date {
  color: #00172D;
  font-weight: 500;
  font-size: 15px;
  text-align: left;
  text-transform: capitalize;
  margin-bottom: 10px;
}

.about-us .excellence .news-events .news-section .bottom-news p.news-detail {
  color: #777B80;
  font-weight: 400;
  font-size: 16px;
  text-align: left;
  text-transform: inherit;
  margin-bottom: 10px;
}

.about-us .excellence .news-events .news-section .bottom-news .read-more {
  float: right;
  color: #152238;
  font-size: 14px;
  font-weight: 400;
}

.about-us .excellence .news-events .news-section .bottom-news .read-more svg {
  border: 1.2px solid #000;
  border-radius: 50%;
  padding: 1px;
  font-size: 12px;
}

/* Careers page */

/*- about us page-*/

.careers .site-pages {
  background-image: url(../images/about.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 275px;
  width: 100%;
  display: flex;
  align-items: end;
  position: relative;
}

.careers .about-company {
  padding: 50px;
  background: transparent linear-gradient(180deg, #E9F6FC 0%, #fff 100%);
}

.careers .vision-box {
  display: flex;
  margin-bottom: 60px;
}

.careers .vision-box img {
  height: 60px;
}

.careers .vision-box h2 {
  color: #00172D;
  font-size: 22px;
  font-weight: 600;
  text-align: left;
  text-transform: capitalize;
}

.careers .vision-box .vision-text {
  padding: 0 40px;
  max-width: 450px;
}

.careers .vision-box .vision-text p {
  color: #00172D;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  text-transform: inherit;
  line-height: 28px;
}

.careers .sec-top-title {
  color: #43C2EC;
  letter-spacing: 4px;
  font-weight: 400;
  font-size: 17px;
  text-align: center;
  text-transform: uppercase;
}

.careers .sec-bottom h2 {
  color: #0052A2;
  font-size: 45px;
  font-weight: 600;
  text-align: left;
  text-transform: inherit;
  line-height: 58px;
  text-align: center;
  margin-top: 10px;
}

.careers .sec-bottom p {
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  text-transform: inherit;
  text-align: justify;
  line-height: 28px;
  text-align: center;
}

.careers .right-side {
  margin-top: 100px;
}

.careers .about-offers {
  padding: 50px;
  background: transparent linear-gradient(0deg, #E9F6FC 0%, #fff 100%);
}

.careers .about-offers .oppenings {
  margin: 50px 0;
}

.careers .about-offers .oppenings .job {
  padding: 35px 0;
  border-top: 1px solid #E6E6F0;
}

.careers .about-offers .oppenings .job:last-child {
  border-bottom: 1px solid #E6E6F0;
}

.careers .about-offers .oppenings .job h2 {
  color: #152238;
  font-size: 22px;
  font-weight: 600;
  text-align: left;
  opacity: 1;
  letter-spacing: 0;
}

.careers .about-offers .oppenings .job p.experience {
  color: #3E5871;
  font-size: 15px;
  font-weight: 400;
  text-transform: inherit;
  text-align: justify;
  margin-bottom: 12px;
}

.careers .about-offers .oppenings .job p.job-detail {
  color: #00172D;
  font-size: 18px;
  font-weight: 400;
  text-transform: inherit;
  text-align: justify;
}

.careers .about-offers .oppenings .job .read-more {
  color: #152238;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
}

.careers .about-offers .oppenings .job .apply-btn {
  float: right;
}

.apply-btn {
  background-color: #01A0C6;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  text-transform: capitalize;
  padding: 8px 15px;
  border-radius: 4px;
  border: none;
}

.apply-btn:hover,
.apply-btn:focus {
  background-color: #01A0C6;
  box-shadow: none;
  outline: none;
  color: #fff;
}

.careers .about-offers .oppenings .job .apply-now {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.careers .about-offers .oppenings .job .apply-now a {
  color: inherit;
  text-decoration: none;
}

.careers .about-offers .process .sec-top-title,
.careers .about-offers .process .sec-bottom h2,
.careers .about-offers .process .sec-bottom p {
  text-align: left;
}

.careers .about-offers .process .qualities {
  margin: 40px 0;
}

.careers .about-offers .process .qualities .quality-text {
  margin: 25px 0;
}

.careers .about-offers .process .qualities .quality-text h2 {
  color: #00CDAC;
  font-weight: 600;
  font-size: 45px;
  text-transform: capitalize;
}

.careers .about-offers .process .qualities .quality-text h4 {
  color: #00172D;
  font-weight: 600;
  font-size: 22px;
  text-transform: capitalize;
}

.careers .about-offers .process .qualities .quality-text p {
  color: #000000;
  font-weight: 500;
  font-size: 18px;
  text-transform: inherit;
}

.career-detail .career-banner {
  background: transparent linear-gradient(180deg, #0B1379 0%, #0D1056 100%);
  padding: 50px;
}

.career-detail .career-banner .sec-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 148px;
}

.career-detail .career-banner .sec-bottom h2,
.career-detail .career-banner .sec-bottom p {
  color: #fff;
}

.career-detail .career-banner .sec-bottom p {
  font-size: 18px;
  padding-right: 0;
}

.career-detail .career-banner .button-sec {
  margin: 15px 0;
}

.career-detail .career-banner .button-sec button {
  margin: 10px;
  width: 120px;
  border-radius: 2px;
}

.career-detail .career-banner .button-sec button a {
  color: inherit;
  text-decoration: none;
}

.career-detail .oppenings .job h2 {
  font-size: 32px !important;
  font-weight: 500;
}

.career-detail .oppenings .job .requirements {
  display: flex;
  align-items: left;
}

.career-detail .oppenings .job .requirements p,
.career-detail .oppenings .job .requirements p.experience {
  border-right: 1.4px solid #C6E6F0;
  color: #00172D;
  font-size: 17px;
  padding: 0 10px;
  margin-bottom: 0;
}

.career-detail .oppenings .job .requirements p:first-child {
  padding-left: 0;
}

.career-detail .oppenings .job ul {
  padding-left: 0;
  margin: 40px 0;
}

.career-detail .oppenings .job ul h4 {
  font-size: 22px;
  font-weight: 500;
  text-align: left;
  text-decoration: none;
  letter-spacing: 0;
  opacity: 1;
  color: #152238;
  margin-bottom: 22px;
}

.career-detail .oppenings .job ul li {
  list-style: none;
  font-size: 18px;
  font-weight: 400;
  color: #152238;
  text-align: left;
  text-decoration: none;
  margin: 15px 0;
  display: flex;
  align-items: baseline;
}

.career-detail .oppenings .job ul li img {
  height: 12px;
  margin-right: 15px;
}

.career-detail .about-offers .oppenings .job {
  border: none !important;
  padding: 0 !important;
}

.career-detail .about-offers .oppenings {
  margin: 0px 0 !important;
}

.career-detail .contact-form {
  padding: 50px;
  margin: 60px 190px;
}

.career-detail .contact-form .apply-here-mobile {
  display: none;
}

.career-detail .contact-form .apply-here-mobile h4 {
  font-size: 20px;
  font-weight: 500;
  color: #01A0C6;
  text-align: left;
  text-decoration: none;
  letter-spacing: 0.32px;
}

.career-detail .contact-form .apply-here-mobile p {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #152238;
}

.career-detail .contact-form form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.career-detail .contact-form form h1 {
  font-size: 36px;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  letter-spacing: 0;
  margin-bottom: 20px;
}

.career-detail .contact-form .apply-btn {
  width: 100%;
}

.career-detail .contact-form .submit-sec {
  margin: 20px 0;
}

.career-detail .contact-form form .file-upload {
  background-color: #fff;
  padding: 10px;
  border: 1.4px dashed #00172D;
  margin-top: 45px;
  width: 100%;
  height: 68px;
}

.career-detail .contact-form form .file-upload input {
  opacity: 0;
}

.career-detail .contact-form form .file-upload .drag {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  top: 3px;
}

.career-detail .contact-form form .file-upload .drag p {
  color: #152238;
  font-size: 15px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 0;
}

.career-detail .contact-form form .file-upload .drag .apply-btn {
  width: auto;
  text-decoration: none;
  background-color: #02AABD;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Home */

.home-page .home-banner {
  /* background: transparent linear-gradient(180deg, #0B1379 0%, #0D1056 100%); */
  background-image: url(../images/home-banner.jpeg);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
}

.home-page .home-banner .overlay {
  background: linear-gradient(180deg, #0b1379fc 0%, #0d1056cc 100%);
  opacity: 2;
  z-index: 9999;
  height: 100%;
  left: 0;
  padding: 50px;
  top: 0;
  width: 100%;
}

.home-page .home-banner .sec-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 132px 148px;
}

.home-page .home-banner .sec-bottom h2 {
  color: #fff;
  font-size: 55px;
  font-weight: 700;
  text-align: left;
  text-transform: inherit;
  line-height: 73px;
  text-align: center;
  margin-top: 10px;
}

.home-page .home-banner .sec-bottom p {
  font-size: 16px;
  padding-right: 0;
}

.home-page .home-banner .button-sec {
  margin: 15px 0;
}

.home-page .home-banner .button-sec button {
  margin: 10px;
  width: 120px;
  border-radius: 2px;
}

.home-page .home-about-us {
  background: transparent linear-gradient(309deg, #0C126D 0%, #0D1056 100%);
  padding: 50px;
}

.home-page .home-about .sec-bottom h2 {
  color: #FFFFFF;
  font-size: 45px;
  font-weight: 600;
  text-align: left;
  text-transform: inherit;
  line-height: 58px;
  text-align: left;
  margin-top: 10px;
}

.home-page .home-about .sec-bottom p {
  color: #01A0C6;
  font-size: 17px;
  font-weight: 500;
  text-transform: inherit;
  text-align: justify;
  line-height: 28px;
  text-align: left;
  letter-spacing: 6.6px;
}

.home-page .home-about .about-us p {
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  text-decoration: none;
  color: #fff;
  letter-spacing: 0.48px;
  line-height: 28px;
}

.home-page .home-about-us .right-sec {
  background-image: url(../images/home-about.svg);
  background-repeat: no-repeat;
  background-size: 365px;
  background-position: left;
  height: 100%;
  width: 100%;
}

.home-page .home-services {
  background: #F2FAFC;
  padding: 100px 50px;
}

.home-page .home-services .sec-bottom h2 {
  color: #0052A2;
  font-size: 45px;
  font-weight: 600;
  text-align: left;
  text-transform: inherit;
  line-height: 58px;
  text-align: left;
  margin-top: 10px;
}

.home-page .home-services .sec-bottom p {
  color: #01A0C6;
  font-size: 18px;
  font-weight: 500;
  text-transform: inherit;
  text-align: justify;
  line-height: 28px;
  text-align: left;
  letter-spacing: 6.6px;
}

.home-page .home-services .service-desc p {
  color: #000000;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  letter-spacing: 0.24px;
}

.home-page .home-services .sevice-left .service-box img {
  height: 195px;
  width: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.home-page .home-services .sevice-left .service-box .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  color: #FFF;
  border-radius: 4px;
}

.home-page .home-services .sevice-left .service-box .overlay img {
  height: 45px !important;
  padding: 5px;
  border-radius: 50%;
  background: rgb(0 0 0 / 36%);
  width: auto !important;
}

.home-page .home-services .sevice-left .service-box {
  position: relative;
  margin: 0;
  border-radius: 4px;
}

.home-page .home-services .sevice-left .service-box .overlay {
  display: flex;
  background: rgb(0 23 45 / 49%);
  align-items: flex-end;
  padding: 20px;
}

.home-page .home-services .sevice-left .service-box .overlay h2 {
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  font-size: 16px;
  font-weight: 600;
}

.home-page .home-services .sevice-left .service-list {
  background: #E3F4FC;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 195px;
  margin-bottom: 4px;
}

.home-page .home-services .sevice-left .service-list img {
  height: 100px;
  object-fit: contain;
}

.home-page .home-services .sevice-left .service-list h2 {
  color: #02AABD;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.44px;
  margin-top: 20px;
  white-space: nowrap;
}

.home-page .home-services .home-service {
  margin-left: 20px;
  margin-top: 40px;
}

.home-page .home-commitment {
  background: transparent linear-gradient(300deg, #0C126D 0%, #0D1056 100%);
  padding: 50px;
}

.home-page .home-commit .sec-bottom h2 {
  color: #FFFFFF;
  font-size: 45px;
  font-weight: 600;
  text-align: left;
  text-transform: inherit;
  line-height: 58px;
  text-align: left;
  margin-top: 10px;
}

.home-page .home-commit {
  padding: 100px 0;
}

.home-page .home-commit .sec-bottom p {
  color: #01A0C6;
  font-size: 17px;
  font-weight: 500;
  text-transform: inherit;
  text-align: justify;
  text-align: left;
  letter-spacing: 6.6px;
}

.home-page .home-commit .about-us p {
  font-size: 14.5px;
  font-weight: 400;
  text-align: left;
  text-decoration: none;
  color: #fff;
  letter-spacing: 0.48px;
  line-height: 28px;
}

.home-page .home-commitment .commmit-box {
  padding: 15px 25px;
}

.home-page .home-commitment .commmit-box h2 {
  letter-spacing: 0.64px;
  color: #00AAE2;
  font-size: 24px;
  font-weight: 600;
  text-align: left;
  margin-bottom: 8px;
}

.home-page .home-commitment .commmit-box p {
  color: #FFFFFF;
  font-weight: 400;
  font-size: 18px;
  text-align: left;
  letter-spacing: 0.48px;
  text-align: left;
  margin-bottom: 4px;
  padding-right: 30px;
}

.home-page .home-commitment .right-sec {
  height: 100%;
  overflow: hidden;
}

.home-page .home-commitment .commit-right::-webkit-scrollbar {
  width: 0 !important
}

.home-page .home-commitment .commit-right::-webkit-scrollbar {
  display: none;
}

.home-page .home-portfolio {
  background: #E9F6FC;
  padding: 70px 50px;
}

.home-page .home-portfolio .sec-bottom h2 {
  color: #0052A2;
  font-size: 45px;
  font-weight: 600;
  text-align: center;
  text-transform: inherit;
  line-height: 58px;
  margin-top: 10px;
}

.home-page .home-portfolio .sec-bottom p {
  color: #01A0C6;
  font-size: 17px;
  font-weight: 500;
  text-transform: inherit;
  text-align: center;
  letter-spacing: 6.6px;
}

.home-page .home-portfolio .portfolio-desc p {
  color: #00172D;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.24px;
  margin: 0 180px;
}

.home-page .home-portfolio .carousel,
.home-page .home-portfolio .portfolio-slider {
  padding: 40px 0px;
}

.home-page .home-portfolio .carousel-inner .item .img-item {
  display: flex;
}

.home-page .home-portfolio .carousel-inner .item img.logo,
.home-page .home-portfolio .react-multi-carousel-list img.logo {
  height: 28px;
}

.home-page .home-portfolio .carousel-inner .item img,
.home-page .home-portfolio .react-multi-carousel-list img {
  height: 150px;
  object-fit: contain;
}

.home-page .home-portfolio .carousel-inner .item .one-side,
.home-page .home-portfolio .react-multi-carousel-list .one-side {
  display: flex;
  align-items: center;
  flex-direction: column;
  background: transparent linear-gradient(180deg, #FFFFFF 0%, #E9F6FC 100%);
  margin: 10px;
  padding: 40px 20px;
}

.home-page .home-portfolio .carousel-inner .item .one-side p,
.home-page .home-portfolio .react-multi-carousel-list .one-side p {
  text-align: center;
  padding: 15px;
  color: #00172D;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
  margin-top: 10px;
  min-height: 100%;
  max-height: 100%;
}

.home-page .home-portfolio .carousel-inner .item .one-side a,
.home-page .home-portfolio .react-multi-carousel-list .one-side a {
  position: absolute;
  z-index: 0;
  bottom: 58px;
  color: #01A0C6;
  font-size: 14px;
  text-decoration: none;
  text-align: center;
  text-transform: lowercase;
  font-weight: 500;
}

.home-page .home-portfolio .carousel-inner .item img:not(.logo),
.home-page .home-portfolio .react-multi-carousel-list img:not(.logo) {
  position: relative;
  z-index: 1;
}

.home-page .home-portfolio .portfolio-slider .react-multiple-carousel__arrow {
  background-color: transparent;
}

.home-page .home-portfolio .portfolio-slider .react-multiple-carousel__arrow::before {
  color: #7FCFE2;
  font-size: 30px;
  font-weight: 600;
}

.home-page .home-portfolio .portfolio-slider .react-multiple-carousel__arrow:focus {
  box-shadow: none;
  outline: none;
}

.home-page .home-portfolio .portfolio-slider .react-multiple-carousel__arrow--left {
  left: calc(0% + 1px);
}

.home-page .home-portfolio .portfolio-slider .react-multiple-carousel__arrow--right {
  right: calc(0% + 1px);
}

.home-page .home-portfolio .carousel-inner .item img:not(.logo):hover,
.home-page .home-portfolio .react-multi-carousel-list img:not(.logo):hover {
  animation: zoom-in-zoom-out 1s ease;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(0.6, 0.6);
  }
}

.home-page .home-portfolio .carousel-control {
  background-image: none !important;
}

.home-page .home-portfolio .carousel-control {
  text-shadow: none;
}

.home-page .home-portfolio .carousel-control .glyphicon::before {
  color: #7FCFE2;
}

.home-page .home-technologies {
  background: transparent linear-gradient(300deg, #0C126D 0%, #0D1056 100%);
  padding: 50px;
}

.home-page .home-tech .sec-bottom h2 {
  color: #FFFFFF;
  font-size: 45px;
  font-weight: 600;
  text-transform: inherit;
  line-height: 58px;
  text-align: center;
  margin-top: 10px;
}

.home-page .home-tech .sec-bottom p {
  color: #01A0C6;
  font-size: 17px;
  font-weight: 500;
  text-transform: inherit;
  text-align: center;
  line-height: 28px;
  letter-spacing: 6.6px;
}

.home-page .home-tech .tools-list {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  flex-wrap: wrap;
}

.home-page .home-tech .tools-list .tool-box {
  padding: 20px 10px;
  border-radius: 4px;
  margin: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
}

.home-page .home-tech .tools-list .tool-box img {
  height: 50px;
  object-fit: contain;
}

.home-page .home-tech .tools-list {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  flex-wrap: wrap;
}

.home-page .home-tech .tools-list .tool-box {
  padding: 20px 10px;
  border-radius: 4px;
  margin: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
}

.home-page .home-tech .tools-list .normal {
  animation: move normal 2s linear infinite;
}

@keyframes move {
  from {
    left: 0;
  }

  to {
    left: 300px;
  }
}

.home-page .home-tech .tools-list .tool-box img {
  height: 50px;
  object-fit: contain;
}

.home-page .home-clients .tools-list {
  display: flex;
  align-items: center;
  padding: 30px;
  flex-wrap: wrap;
  justify-content: center;
}

.home-page .home-clients .tools-list .tool-box {
  padding: 12px;
  border-radius: 4px;
  margin: 15px 15px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
}

.home-page .home-clients .tools-list .tool-box {
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: center;
}

.home-page .home-clients .tools-list .tool-box.digitaz {
  background-image: url(../images/tools/digitaz\ L\ G.png);
}

.home-page .home-clients .tools-list .tool-box:hover.digitaz {
  background-image: url(../images/tools/digitaz\ L\ C.png);
}

.home-page .home-clients .tools-list .tool-box.Bp {
  background-image: url(../images/tools/BP\ L\ G.png);
}

.home-page .home-clients .tools-list .tool-box:hover.Bp {
  background-image: url(../images/tools/BP\ L\ C.png);
}

.home-page .home-clients .tools-list .tool-box.jersey {
  background-image: url(../images/tools/Jersey\ L\ G.png);
}

.home-page .home-clients .tools-list .tool-box:hover.jersey {
  background-image: url(../images/tools/Jersey\ L\ C.png);
}

.home-page .home-clients .tools-list .tool-box.jersey-group {
  background-image: url(../images/tools/jersey-g.png);
}

.home-page .home-clients .tools-list .tool-box:hover.jersey-group {
  background-image: url(../images/tools/jersey-g-h.png);
}

.home-page .home-clients .tools-list .tool-box.belaoryx {
  background-image: url(../images/tools/BX\ L\ G.png);
}

.home-page .home-clients .tools-list .tool-box:hover.belaoryx {
  background-image: url(../images/tools/BX\ L\ C.png);
}

.home-page .home-clients .tools-list .tool-box.qadnet {
  background-image: url(../images/tools/QAD\ L\ C\ G.png);
}

.home-page .home-clients .tools-list .tool-box:hover.qadnet {
  background-image: url(../images/tools/QAD\ L\ C\ 1.png);
}

.home-page .home-clients .tools-list .tool-box.meplinks {
  background-image: url(../images/tools/MEP\ LINKS\ L\ G.png);
}

.home-page .home-clients .tools-list .tool-box:hover.meplinks {
  background-image: url(../images/tools/MEP\ LINKS\ L\ C.png);
}

.home-page .home-support {
  background: transparent linear-gradient(180deg, #E9F6FC 0%, #FFFFFF 100%);
  padding: 100px 50px;
}

.home-page .home-support .sec-bottom h2 {
  color: #0052A2;
  font-size: 45px;
  font-weight: 600;
  text-align: left;
  text-transform: inherit;
  line-height: 58px;
  margin-top: 10px;
}

.home-page .home-support .sec-bottom p {
  color: #01A0C6;
  font-size: 17px;
  font-weight: 500;
  text-transform: inherit;
  text-align: left;
  letter-spacing: 6.6px;
}

.home-page .home-support .service-desc p {
  color: #000000;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  letter-spacing: 0.24px;
  padding-right: 150px;
}

.home-page .home-support .sevice-left .service-list {
  background: transparent linear-gradient(180deg, #0B1379 0%, #0D1056 100%);
  filter: drop-shadow(2px 3px 4px #00000033);
  padding: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 180px;
  width: 210px;
  margin-bottom: 4px;
}

.home-page .home-support .sevice-left .service-list img {
  height: 90px;
  object-fit: contain;
}

.home-page .home-support .sevice-left .service-list h2 {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.44px;
  margin-top: 20px;
  white-space: nowrap;
}

.home-page .home-support .sevice-left {
  height: 100%;
  overflow: hidden;
  margin-top: -42px;
  margin-bottom: 38px;
}

.home-page .home-support .sevice-left .service-box {
  height: 460px;
  overflow-y: auto;
}

.home-page .home-support .sevice-left .service-box::-webkit-scrollbar {
  width: 0 !important
}

.home-page .home-support .sevice-left .service-box::-webkit-scrollbar {
  display: none;
}

/* Portfolio */
.portfolio-page {
  background: transparent linear-gradient(180deg, #E9F6FC 0%, #FFFFFF 100%);
}

.portfolio-page .site-pages.port-tech {
  background-image: url(../images/portfolio.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 275px;
  width: 100%;
  display: flex;
  align-items: end;
  position: relative;
}

.portfolio-page .our-works {
  background: transparent linear-gradient(180deg, #0B1379 0%, #0D1056 100%);
  padding: 80px 50px 140px;
}

.portfolio-page .our-works .sec-bottom h2 {
  color: #FFFFFF;
  font-size: 45px;
  font-weight: 600;
  text-transform: inherit;
  line-height: 58px;
  text-align: center;
  margin-top: 10px;
}

.portfolio-page .our-works .sec-bottom p {
  color: #01A0C6;
  font-size: 17px;
  font-weight: 500;
  text-transform: inherit;
  text-align: center;
  line-height: 28px;
  letter-spacing: 6.6px;
}

.portfolio-page .our-works #scroll-down {
  cursor: pointer;
}

.portfolio-page .our-works #scroll-down::after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 80%;
}

.portfolio-page .our-works #scroll-down a {
  padding-top: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
}

.portfolio-page .our-works #scroll-down a span {
  position: absolute;
  width: 24px;
  height: 44px;
  border: 2px solid #fff;
  border-radius: 50px;
  box-sizing: border-box;
  margin-top: 100px;
}

.portfolio-page .our-works #scroll-down a span::before {
  position: absolute;
  top: 10px;
  left: 50%;
  content: '';
  width: 6px;
  height: 6px;
  margin-left: -3px;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation: sdb10 2s infinite;
  animation: sdb10 2s infinite;
  box-sizing: border-box;
}

@-webkit-keyframes sdb10 {
  0% {
    -webkit-transform: translate(0, 0);
    opacity: 0;
  }

  40% {
    opacity: 1;
  }

  80% {
    -webkit-transform: translate(0, 20px);
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes sdb10 {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }

  40% {
    opacity: 1;
  }

  80% {
    transform: translate(0, 20px);
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.portfolio-page .works-list {
  background-color: #E9F6FC;
  padding: 50px;
}

.portfolio-page .works-list a {
  text-decoration: none;
}

.portfolio-page .works-list .works-item {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  background-color: #fff;
  margin: 15px 0;
  min-height: 100%;
  max-height: 100%;
  position: relative;
}

.portfolio-page .works-list .works-item.snape-cookie {
  background: #C72D4A;
}

.portfolio-page .works-list .works-item.meplinks {
  background: #021E3E;
}

.portfolio-page .works-list .works-item.jersey {
  background: #C53F39;
}

.portfolio-page .works-list .works-item .work-inner {
  padding: 20px;
}

.portfolio-page .works-list .works-item .work-inner h2 {
  color: #00172D;
  font-size: 26px;
  font-weight: 600;
  text-align: left;
  text-transform: math-auto;
  margin-top: 0;
}

.portfolio-page .works-list .works-item .work-inner h4 {
  color: #00172D;
  font-size: 15px;
  font-weight: 600;
  text-align: left;
  text-transform: math-auto;
  margin-bottom: 20px;
}

.portfolio-page .works-list .works-item .work-inner h4 span {
  border-right: 1.4px solid #00172D;
  margin: 0px 4px;
  font-size: 12px;
}

.portfolio-page .works-list .works-item .work-inner p {
  color: #00172D;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  text-transform: inherit;
  text-align: justify;
}

.portfolio-page .works-list .works-item img {
  height: 250px;
  object-fit: cover;
  width: 100%;
}

.portfolio-page .works-list .works-item .read-more {
  float: right;
  color: #152238;
  font-size: 13px;
  font-weight: 400;
  position: absolute;
  right: 15px;
  bottom: 15px;
}

.portfolio-page .form-section .contact-form .form-check .form-error {
  margin-left: -20px;
}

.Services .form-section .contact-form .form-check label {
  margin-left: 10px;
}

.portfolio-page .form-section .expert-box.letus-know h4 {
  font-size: 20px;
  font-weight: 600;
}

.portfolio-page .form-section .expert-box {
  margin-bottom: 40px;
}

.portfolio-page .expert-box h4 {
  color: #00AAE2;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  opacity: 1;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.portfolio-page .expert-box h4 img {
  height: 20px;
  margin-right: 10px;
}

.portfolio-page .expert-box p {
  color: #152238;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  margin-bottom: 10px;
  margin-right: 10px;
  opacity: 1;
}

.portfolio-page #contactform {
  padding: 50px;
}

.portfolio-page .form-section .contact-form {
  margin-top: -34px;
}

.portfolio-page .form-section .contact-form .submit-btn {
  background: #01A0C6;
  border: none;
  border-radius: 4px;
  box-shadow: none;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  margin-top: 20px;
  opacity: 1;
  outline: none;
  padding: 10px 15px;
  text-align: center;
  float: right;
}

.portfolio-page .bread-crumbs {
  padding: 20px 50px;
}

.portfolio-page .bread-crumbs .breadcrumb-arrow {
  margin-right: 15px;
}

.portfolio-page .bread-crumbs .breadcrumb-arrow::before {
  content: "\e825";
  font-size: 16px;
  font-weight: 500;
  color: #81C0C7;
  font-family: revicons;
  text-align: center;
  z-index: 2;
  position: relative;
}

.portfolio-page .bread-crumbs a {
  color: #8FD5F0;
  font-size: 15px;
  font-weight: 400;
  margin-right: 15px;
  text-decoration: none;
}

.portfolio-page .bread-crumbs a:hover {
  text-decoration: underline;
}

.portfolio-page .bread-crumbs .breadcrumb-active {
  cursor: default;
  color: #00AAE2;
  font-weight: 600;
}

.portfolio-page .bread-crumbs .breadcrumb-active:hover {
  text-decoration: none;
}

.portfolio-page .works-list.detail {
  background-color: #fff;
}

.portfolio-page .works-list.detail .works-item {
  display: block;
  padding: 50px;
  margin: 0;
}

.portfolio-page .works-list.detail .works-item p {
  color: #000000;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  margin-top: 20px;
}

.portfolio-page .works-list.detail .works-item .logo {
  height: 40px;
  object-fit: contain;
}

.portfolio-page .works-list.detail .works-item h4 {
  font-size: 20px;
  font-weight: 600;
}

.portfolio-page .works-list.detail .works-item h4.challenge {
  color: #C60136;
}

.portfolio-page .works-list.detail .works-item h4.solution {
  color: #5BAF1D;
}

.portfolio-page .works-list.detail .works-item h4.technical {
  color: #0052A2;
}

.portfolio-page .works-list.detail .react-multi-carousel-dot-list {
  position: absolute;
  bottom: unset;
  display: block;
  left: unset;
  right: 0;
}

.portfolio-page .works-list.detail .left-folio img {
  height: 280px;
}

.portfolio-page .works-list.detail .react-multi-carousel-dot--active button {
  background: #00AAE2 !important;
  width: 8px !important;
  height: 20px !important;
  border-radius: 8px !important;
  border-color: #00AAE2 !important;
}

.portfolio-page .works-list.detail .react-multi-carousel-dot button {
  background: #8FD5F0;
  width: 8px;
  height: 8px;
  border-color: #8FD5F0;
}

.portfolio-page .works-list.detail .react-multiple-carousel__arrow--left {
  display: none;
}

.portfolio-page .works-list.detail .react-multiple-carousel__arrow--right {
  min-width: 100% !important;
  min-height: 100% !important;
  opacity: 0;
}

/* Technologies */

.technologies .site-pages.technology {
  background-image: url(../images/technology.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 275px;
  width: 100%;
  display: flex;
  align-items: end;
  position: relative;
}

.technologies .framework-tools {
  background-color: #E9F6FC;
  padding: 50px;
}

.technologies .backend-tools {
  background: transparent linear-gradient(180deg, #0B1379 0%, #0D1056 100%);
  padding: 50px;
}

.technologies .framework-tools .sec-bottom,
.technologies .backend-tools .sec-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.technologies .framework-tools .sec-bottom h2,
.technologies .backend-tools .sec-bottom h2 {
  color: #0052A2;
  font-size: 45px;
  font-weight: 600;
  text-align: left;
  text-transform: inherit;
  line-height: 58px;
  text-align: center;
  letter-spacing: 1.44px;
  margin-top: 10px;
}

.technologies .backend-tools .sec-bottom h2 {
  color: #fff !important;
}

.technologies .framework-tools .sec-bottom p,
.technologies .backend-tools .sec-bottom p {
  color: #43C2EC;
  font-size: 17px;
  font-weight: 500;
  text-transform: inherit;
  text-align: justify;
  text-align: center;
  letter-spacing: 6.6px;
}

.technologies .framework-tools .tools-list,
.technologies .backend-tools .tools-list {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  flex-wrap: wrap;
}

.technologies .framework-tools .tools-list .tool-box,
.technologies .backend-tools .tools-list .tool-box {
  background-color: #FFFFFF;
  padding: 20px 10px;
  border-radius: 4px;
  margin: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 160px;
}

.technologies .framework-tools .tools-list .tool-box img,
.technologies .backend-tools .tools-list .tool-box img {
  height: 80px;
  object-fit: contain;
}

.technologies .framework-tools .tools-list .tool-box p,
.technologies .backend-tools .tools-list .tool-box p {
  color: #152238;
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0;
}

.technologies .contactform {
  padding: 50px;
}

.technologies .contactform .form-section .expert-box {
  margin-bottom: 40px;
  margin-top: 24px;
}

.technologies .contactform .expert-box h4 {
  color: #00AAE2;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  opacity: 1;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.technologies .contactform .expert-box h4 img {
  height: 20px;
  margin-right: 10px;
}

.technologies .contactform .expert-box p {
  color: #152238;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  margin-bottom: 10px;
  margin-right: 10px;
  opacity: 1;
}

.technologies .contactform .form-section .expert-box p.date {
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.5;
  font-size: 14px;
  font-weight: 400;
}

.technologies .contactform .submit-btn {
  background: #01A0C6;
  border: none;
  border-radius: 4px;
  box-shadow: none;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  margin-top: 20px;
  opacity: 1;
  outline: none;
  padding: 10px 20px;
  text-align: center;
  float: right;
}

/* Services */

.Services {
  background: transparent linear-gradient(180deg, #E9F6FC 0%, #FFFFFF 100%);
}

.Services .site-pages.service {
  background-image: url(../images/contact.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 275px;
  width: 100%;
  display: flex;
  align-items: end;
  position: relative;
}

.Services .business-industry {
  padding: 50px;
}

.Services .business-industry .sec-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Services .business-industry .sec-bottom h2 {
  color: #110B79;
  font-size: 38px;
  font-weight: 600;
  text-align: left;
  text-transform: inherit;
  line-height: 58px;
  text-align: center;
  letter-spacing: 1.44px;
  margin-top: 10px;
}

.Services .business-industry .sec-bottom p {
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  text-transform: inherit;
  padding: 0 144px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0px;
}

.Services .business-industry .tools-list {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  flex-wrap: wrap;
}

.Services .business-industry .tools-list .tool-box {
  background-color: #FFFFFF;
  padding: 20px 10px;
  border-radius: 4px;
  margin: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 160px;
  box-shadow: 0 0px 0px 0 #0000001A, 0 2px 10px 0 #0000001A;
}

.Services .business-industry .tools-list .tool-box img {
  height: 80px;
  object-fit: contain;
}

.Services .business-industry .tools-list .tool-box p {
  color: #152238;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0;
}

.Services .our-services {
  padding: 50px 50px 0px;
}

.Services .our-services .service-box img {
  height: 270px;
  width: 100%;
  object-fit: cover;
}

.Services .our-services .service-box .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  color: #FFF;
}

.Services .our-services .service-box .overlay img {
  height: 45px !important;
  padding: 5px;
  border-radius: 50%;
  background: rgb(0 0 0 / 36%);
  width: auto !important;
}

.Services .service-box {
  position: relative;
  margin: 7.5px 0;
}

.Services .our-services .service-box .overlay {
  display: flex;
  background: rgb(0 23 45 / 49%);
  align-items: flex-end;
  padding: 20px;
}

.Services .our-services .service-box .overlay h2 {
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  font-size: 18px;
  font-weight: 600;
}

.Services .form-section .contact-form .form-check .form-error {
  margin-left: -20px;
}

.Services .form-section .contact-form .form-check label {
  margin-left: 10px;
}

.Services .form-section .expert-box.letus-know h4 {
  font-size: 20px;
  font-weight: 600;
}

.Services .form-section .expert-box {
  margin-bottom: 40px;
}

.Services .expert-box h4 {
  color: #00AAE2;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  opacity: 1;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.Services .expert-box h4 img {
  height: 20px;
  margin-right: 10px;
}

.Services .expert-box p {
  color: #152238;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  margin-bottom: 10px;
  margin-right: 10px;
  opacity: 1;
}

.Services #contactform {
  padding: 50px;
}

.Services .form-section .contact-form {
  margin-top: -34px;
}

.Services .form-section .contact-form .submit-btn {
  background: #01A0C6;
  border: none;
  border-radius: 4px;
  box-shadow: none;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  margin-top: 20px;
  opacity: 1;
  outline: none;
  padding: 10px 15px;
  text-align: center;
  float: right;
}

.alert {
  display: none;
  margin-bottom: 0;
}

/* Services */


/* odoo */
#odooSection #odooBannerImgSection .bg-img {
  background-size: cover !important;
  background-position: center;
  background-repeat: no-repeat !important;
  height: 477px;
  width: 100%;
  position: relative;
  text-align: center;
  background:
    linear-gradient(135deg, #714b67, #6f4c6a99, #75516c),
    url("../images/odoo/banner-img.png");
}

#odooSection #odooBannerImgSection .bg-img h1 {
  position: absolute;
  bottom: 194px;
  left: 156px;
  color: #ffffff;
  font-weight: 600;
  font-size: 44px;
}

#odooSection #odooPartnershipSection {
  background: linear-gradient(to bottom, #714b672f, #9e84971a, #fbfafbf9, #f3f4f6);
}

#odooSection #odooPartnershipSection h1 {
  text-align: center;
  color: #000000;
  font-weight: 600;
  margin: 0px;
}

#odooSection #odooPartnershipSection p {
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
  color: #000000;
  line-height: 30px;
  font-size: 16px;
}

#odooSection #odooPartnershipSection img {
  width: 100px;
  margin-left: 10px;
}


#odooSection #odooPartnershipSection .odoo-partnership-row .card {
  border: 0;
  background-color: #eeebee;
  height: 178px;
  overflow-y: scroll;
}

#odooSection #odooPartnershipSection .odoo-partnership-row .card h4 {
  color: #343434;
  font-size: 17px;
  font-weight: 600;
}

#odooSection #odooPartnershipSection .odoo-partnership-row .card h4 span {
  color: #6c4d66;
  font-size: 22px;
}

#odooSection #odooPartnershipSection .odoo-partnership-row .card ul {
  margin-top: 12px;
}

#odooSection #odooPartnershipSection .odoo-partnership-row .card li {
  color: #343434;
  font-size: 15px;
}

#odooSection #odooPartnershipSection .odoo-partnership-row .card::-webkit-scrollbar {
  display: none;
}

#odooSection #odooPartnershipSection .odoo-partnership-row .card {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#odooSection #odooServicesSection {
  background: linear-gradient(180deg,
      #F6F6F8 0%,
      #F6F6F8 22%,
      rgba(246, 246, 248, 0.5))
}

#odooSection #odooServicesSection h1 {
  text-align: center;
  color: #000000;
  font-weight: 600;
}

#odooSection #odooServicesSection h4 {
  color: #000000;
  font-size: 18px;
  text-align: center;
  font-weight: 600;
}

#odooSection #odooServicesSection p {
  text-align: center;
  color: #000000;
  line-height: 30px;
  font-size: 16px;
}

#odooSection #odooServicesSection .Odoo-services-row .card {
  background-color: #f1f2f4;
  margin-bottom: 15px;
}

#odooSection #odooServicesSection .Odoo-services-row .card img {
  width: 100%;
}

#odooSection #odooPartnerHighlightsection {
  background-color: #86657d;
}


#odooSection #odooPartnerHighlightsection h2 {
  text-align: center;
  color: #f3f4f6;
  font-size: 28px;
}

#odooSection #odooPartnerHighlightsection h4 {
  color: #f1f2f4;
  margin-top: 0px;
}

#odooSection #odooPartnerHighlightsection p {
  color: #f1f2f4;
  font-size: 16px;
}

#odooSection #odooPartnerHighlightsection img {
  width: 25px;
  margin-right: 10px;
}

#odooSection #odooBusinessSection {
  background: linear-gradient(to bottom, #f3f4f6, #e9ebee);
}

#odooSection #odooBusinessSection h2 {
  text-align: center;
  font-size: 28px;
  color: #141414;
  font-weight: 600;
}

#odooSection #odooBusinessSection p {
  color: #000000;
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 16px;
  margin-top: 18px;
}

/* odoo */