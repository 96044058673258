
.headerWrapper {
  height: 55px;
  display: block;
}

.header-bar {
  position: fixed;
  width: 100%;
  height: 55px;
  z-index: 999;
  background-color: #fff;
  /* box-shadow: 0px 1px 2px rgb(0 0 0 / 12%); */
}

.header-outer-wrapper {
  background-position: top;
  width: 100%;
  height: 100%;
  position: relative;
}

.header-inner-wrapper {
  max-width: 1140px;
  padding: 0 15px;
  margin: 0 auto;
  display: none;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 12%);
}

.logo-wrapper {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
}

.header-bar .logo-wrapper {
  /* height: 87px; */
  position: relative;
  justify-content: center;
}


.header-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  height: 100%;
  z-index: 1;
  margin-left: 750px;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: #78b6fa;
  font-family: inherit;
}

.logo {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.logo-wrapper img {
  display: block;
  height: auto;
  max-height: 55px;
}
.logo .img-hover{
  display: none;
}
.navbar-wrapper:hover .logo .img-hover{
  display: block;
}
.navbar-wrapper:hover .logo .logo-white{
  display: none;
}
.user-menu {
  min-width: 0;
  width: 200px;
  order: 1;
}

nav ul {
  list-style: none;
  list-style-image: none;
}

.menu-item {
  border-left: 1px solid #fff;
  position: relative;
  display: block;
  width: 90px;
}

.menu-item>.anchor {
  color: #fff;
  font-size: 16px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
}

.search-container {
  display: inline-block;
  flex-direction: row;
  position: absolute;
  justify-content: center;
  width: 70px;
  margin-left: 270px;
  order: 2;
}

.navbar-wrapper {
  width: 100%;
  background: transparent linear-gradient(360deg, #0B1379 0%, #0D1056 100%);
  height: 55px;
  /* border: 1px solid #f4f8fa; */
  /* box-shadow: 0px 1px 1px #6b6c7961; */
  box-shadow: none;
}
.navbar-wrapper:hover{
  background: #F2F2F8;
}
.navbar-wrapper:hover .nav-bar a{
  color: #00172D;
}
.navbar-wrapper:hover .nav-bar a:hover{
  color: #110B79 !important;
}
.nav-bar {
  max-width: 1140px;
  /* margin: 0 50px; */
  display: flex;
  align-items: center;
  height: 100%;
}

.nav-bar .parent {
  display: flex;
  padding: 0;
  margin-right: 50px;
  height: 50px;
  position: absolute;
  right: 0;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
}

.nav-bar .parent li {
  margin: 0 20px;
}
.nav-bar .parent li:last-child {
  margin-right: 6px;
}
.nav-bar .anchor.logo-anchor{
  padding: 0;
}
.nav-bar a {
  color: #FFFFFF;
  font-size: 16px;
  font-weight: normal;
  text-decoration: none;
  padding: 8px 0;
}