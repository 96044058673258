.footer-sec  .footer-wrapper .mainfooter .bottom-footer{
  background: transparent linear-gradient(180deg, #0B1379 0%, #040746 100%) 0% 0% no-repeat padding-box;
}

.footer-wrapper {
  z-index: 2;
}

.footer-sec .footer-wrapper .footer-desc {
  margin-bottom: 40px;
  padding-bottom: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid #f0f0f02b;
}

.footer-sec .footer-wrapper .footer-desc h2 {
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  font-size: 26px;
  font-weight: 300;
  line-height: 38px;
}

.footer-sec .footer-wrapper .footer-desc .display-btn {
  background: #18A6EF;
  padding: 15px;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  font-size: 15px;
  font-weight: 400;
  box-shadow: none !important;
  outline: none !important;
  border: none;
  height: 45px;
  margin-right: 12%;
  border-radius: 0px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.footer-sec .footer-wrapper .footer-desc .display-btn a {
  color: inherit;
  text-decoration: none;
}

.footer-sec .footer-wrapper .footer-desc .display-btn img {
  height: 14px;
}

.bottom-footer {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
  padding: 40px 0px;
}

.app-footer {
  justify-content: center;
  margin: auto;
  margin-bottom: 20px;
  margin-top: 20px;
  z-index: 2;
}

.appText {
  color: white;
  font-size: 1rem;
}

.gplayimg {
  margin-left: 30px;
}

.footer-sec .footer-wrapper .toplink {
  z-index: inherit;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}


.toplink a {
  color: white;
}

.footernav {
  height: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 5px;
  margin-bottom: 5px;
  justify-content: center;
  padding: 0px;
}

.footer-sec .footer-wrapper .footerlink {
  display: inline-block;
  margin: 6px 20px;
}
.footer-sec .footer-wrapper .footer-title{
  letter-spacing: 0.52px;
  color: #EFEFF5;
  opacity: 1;
  font-size: 22px;
  font-weight: 600;
}
.footer-sec .footer-wrapper .products .footerlink {
  line-height: 25px;
}

.footer-sec .footer-wrapper .footerlink.phno {
  margin: 0px 20px;
  text-transform: lowercase;
}

.footer-sec .footer-wrapper .footerlink.address {
  margin-bottom: 20px;
}

.footer-sec .footer-wrapper .footerlink.title {
  letter-spacing: 0px;
  color: #FFFFFF;
  text-transform: capitalize;
  opacity: 1;
  font-weight: 400;
  font-size: 20px;
}

.footer-sec .footer-wrapper .footerlink a {
  letter-spacing: 0px;
  color: #EFEFF5;
  text-transform: inherit;
  opacity: 1;
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
  text-decoration: none;
}

.footer-sec .footer-wrapper .footerlink.address a {
  line-height: 33px;
}

.footer-sec .footer-wrapper .footerlink a:hover {
  color: #b8c2d3;
}

.footer-sec .footer-wrapper .logo-wrapper {
  margin: 0;
  align-items: baseline;
}
.footer-sec .footer-wrapper .company-address p{
  letter-spacing: 0px;
  color: #FAFDFE;
  opacity: 1;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  margin: 10px 0
}
.footer-sec .footer-wrapper .company-address p span{
  font-weight: 500;
}
.footer-sec .footer-wrapper .logo-wrapper a img {
  height: 60px;
  margin: 6px 0;
}
.below-footer{
  background: #02065D;
  padding: 15px 0px;
}
.follow-us-sec {
  margin-top: 20px;
}

.follow-us-sec .follow-us {
  margin: 0;
  padding: 0;
  display: flex;
}

.below-footer .followlink {
  margin-right: 10px;
  list-style: none;
}

.below-footer .followlink a img {
  height: 24px;
}

.follow-us-sec .followtitle {
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 0.8;
  font-size: 15px;
  font-weight: 400;
}

.region {
  width: 100%;
  justify-content: center;
}

.regionnav {
  font-size: 13px;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  display: -webkit-box !important;
}

.regionnav span {
  color: white;
  padding-right: 5px;
}

.regionlink {
  margin-left: 0px;
  margin-right: 2px;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}

.regionlink a {
  cursor: pointer;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  font-size: 12px;
  text-decoration: none;
  font-weight: 100;
}

.regionlink a:hover {
  color: #b8c2d3;
}

.copyright {
  width: 50%;
}

.copyright span {
  margin: auto;
  letter-spacing: 0px;
  color: #C3C1DD;
  opacity: 1;
  font-size: 13px;
  font-weight: 100;
}

.designedby a {
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  font-size: 12px;
  font-weight: 100;
}

.copyrightnav {
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
}

.mobile-footer {
  margin-top: 40px;
  display: none;
}

.mobile-footer .list-items .accordion .accordion-item .accordion-header button {
  letter-spacing: 0px;
  color: #FFFFFF;
  text-transform: capitalize;
  opacity: 1;
  font-weight: 400;
  font-size: 24px;
  background: none;
  box-shadow: none;
  outline: none;
  border: none;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  border-radius: 0;
  overflow-anchor: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
}

.mobile-footer .list-items {
  margin-top: 22px;
}

.mobile-footer .footernav {
  margin-top: 24px;
  border-bottom: 1px solid #f0f0f030;
  padding-bottom: 40px;
}

.mobile-footer .footernav .footerlink {
  margin: 6px 6px !important;
}

.mobile-footer .footernav .footerlink.title {
  font-size: 24px;
}

.mobile-footer .footernav .footerlink a {
  font-size: 18px;
}

.mobile-footer .follow-us-sec {
  margin-top: 50px;
}

.mobile-footer .follow-us-sec .followtitle {
  font-size: 20px;
}

.mobile-footer .follow-us-sec .follow-us .followlink a img {
  height: 38px;
}

@media (max-width: 600px) {
  .toplink {
    margin-left: 0px;
    width: 100%;
  }

  .footerlink {
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 0px;
  }

  .footernav {
    flex-direction: column;
  }

  .regionnav {
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: center !important;
  }

  .regionlink {
    justify-content: center;
    margin: auto;
  }

  .copyrightlink {
    justify-content: center;
    margin: auto;
  }
}
@media (max-width: 768px) {
  .footer-address-col {
    flex-basis: 100%;
  }
.footer-col {
flex-basis: 50%;
  }
}
@media (max-width: 565px) {
    .footer-address-col {
        flex-basis: 100%;
      }
    
      .footer-col {
        flex-basis: 100%;
      }
}