/*mobile view*/


@media (max-width: 600px) {
    .navbar-wrapper {
        display: none;
    }

    .headerWrapper {
        height: 51px !important;
    }

    .user-menu {
        display: none !important;
    }

    .header-bar {
        height: 51px !important;
    }

    .logo {
        width: 90px;
        height: 61px !important;
    }

    .background .headerWrapper {
        height: 61px;
    }

    .header-inner-wrapper {
        height: 66px;
        width: 100%;
        display: flex !important;
        background-color: #fff;
    }

    .header-inner-wrapper .logo-wrapper {
        margin-left: unset !important;
        align-items: center;
        margin-top: 0 !important;
    }

    .logo-wrapper {
        margin-top: -45px;
        /* width: 100px; */
        margin-left: auto;
        margin-right: auto;
    }

    #menuToggle {
        display: block;
        /* width: 300px; */
        position: absolute;
        right: 13px;
        z-index: 1;
        padding-top: 28px;
        -webkit-user-select: none;
        user-select: none;
    }

    .sidebar {
        margin-top: 15px;
    }

    #menuToggle a {
        text-decoration: none;
        color: white !important;
        transition: color 0.3s ease;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    #menuToggle a img{
        height: 14px;
    }

    #menuToggle a:hover {
        color: #f4ce24;
    }

    #menuToggle input {
        display: block;
        width: 40px;
        height: 32px;
        position: absolute;
        top: -7px;
        left: -5px;
        cursor: pointer;
        opacity: 0;
        z-index: 2;
        -webkit-touch-callout: none;
    }

    #menuToggle span {
        display: block;
        width: 25px;
        height: 3px;
        margin-bottom: 5px;
        position: relative;
        background: #000000;
        border-radius: 3px;
        z-index: 1;
        transform-origin: 4px 0px;
        transition: cubic-bezier(0.77, 0.2, 0.05, 1),
            background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
    }

    #menuToggle span:first-child {
        transform-origin: 0% 0%;
    }

    #menuToggle span:nth-last-child(2) {
        transform-origin: 0% 100%;
    }

    #menuToggle input:checked~span {
        opacity: 1;
        transform: rotate(45deg) translate(-1px, -4px);
        background: white;
    }

    /* #menuToggle input:checked ~ span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  } */

    #menuToggle input:checked~span:nth-last-child(2) {
        transform: rotate(-45deg) translate(0, 4px);
    }

    #menu {
        position: absolute;
        right: -13px;
        width: 300px;
        min-height: 100%;
        height: 4000px;
        margin: -101px 0 0 0px;
        padding: 35px;
        padding-top: 125px;
        background: #0d105a;
        list-style-type: none;
        -webkit-font-smoothing: antialiased;
        transform-origin: 0% 0%;
        transform: translate(100%, 0);
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    }

    #menu li {
        padding: 10px 0;
        font-size: 20px;
        color: white;
        width: 250px;
    }

    #menuToggle input:checked~ul {
        transform: none;
    }

    .searchMobile {
        width: 20px;
        position: relative;
        margin-left: auto;
        margin-top: -75px;
        display: block;
        justify-content: flex-end;
    }

    .sideNav {
        width: 240px;
        z-index: inherit;
    }

    .active li {
        color: #0487ce !important;
    }

    .search-container {
        display: none;
    }

    .searchToggle {
        width: 20px;
        position: relative;
        margin-left: auto;
        margin-top: -75px;
        display: block;
        justify-content: flex-end;
        background-color: transparent;
        outline: 0;
        border: 0;
    }

    .searchiconmobile {
        fill: white;
    }

    .searchiconmobile:hover {
        fill: #f4ce24;
    }

    .textMobile {
        color: white;
        position: relative;
        margin-left: -30px;
        z-index: 50;
        margin-top: -50px;
        color: #f4ce24;
    }

    .textMobile:hover {
        color: #f4ce24;
    }

    .searchinputMobile {
        width: 100%;
        max-width: 700px;
        border-radius: 3px;
        border: none;
        margin: 27px auto auto;
        display: block !important;
    }

    .buybutton {
        margin: auto auto 60px 50px !important;
    }
    .contact-us .site-pages.contact-us .sec-bottom .sub-title{
        font-size: 34px;
        line-height: 48px;
    }
    .contact-us .site-pages.contact-us .sec-bottom{
        bottom: 8px;
    }
    .about-us .about-teams .sec-bottom p{
        padding-right: 0;
    }
    .about-us .about-teams .person-box img{
        height: auto !important;
    }
    .about-us .designations.mobile-view{
        display: block;
    }
    .about-us .designations:not(.mobile-view){
        display: none;
    }
    .about-us .about-teams .designations.mobile-view .designation-descript{
        display: block !important;
    }
    .about-us .about-teams .designations .designation-descript .total-employee{
        margin: 80px 0;
        padding: 0;
        justify-content: flex-start;
    }
    .about-us .about-teams .designations.mobile-view .nav-item .nav-link.active{
        color: #000000 !important;
    }
    .about-us .about-teams .designations.mobile-view .nav-item .nav-link.nav-link.active svg path {
        d: path('m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z');
    }
    .about-us .inspiring{
        height: auto !important;
    }
    .about-us .inspiring h2{
        font-size: 22px;
        bottom: 0px;
        padding: 0px 0 0 20px;
        line-height: 28px;
    }
    .about-us .inspiring h2 span{
        font-size: 18px;
    }
    .about-us .about-teams .people.mobile-view{
        display: block;
    }
    .about-us .about-teams .people.mobile-view .react-multi-carousel-item{
        margin: 0 8px;
    }
    .about-us .about-teams .people.mobile-view .react-multi-carousel-item:first-child{
        margin-left: 0 !important;
    }
    .about-us .about-teams .people:not(.mobile-view){
        display: none;
    }
    .about-us .vision-mission, .about-us .about-teams{
        padding: 15px;
    }
    .about-us .excellence .news-events .sec-top-title, .about-us .excellence .news-events .sec-bottom .sec-title {
        text-align: left;
    }
    .about-us .excellence .qualities .quality-inner{
        height: auto;
        overflow: hidden;
    }
    .about-us .excellence .news-events {
        margin-top: 50px;
    }
    .about-us .excellence .news-events .p-5{
        padding: 15px !important;
    }
    .careers .about-company, .careers .about-offers{
        padding: 15px;
    }
    .careers .sec-top-title, .careers .sec-bottom h2, .careers .sec-bottom p{
        text-align: left;
    }
    .careers .sec-bottom p{
        padding-right: 0;
    }
    .careers .sec-bottom h2{
        font-size: 24px;
        line-height: 36px;
    }
    .careers .about-offers .oppenings .job .apply-now{
        flex-direction: column;
        align-items: flex-start;
    }
    .careers .about-offers .oppenings .job .apply-btn{
        margin-top: 20px;
    }
    .career-detail .career-banner .sec-bottom{
        padding: 30px 0px 0;
        align-items: baseline;
    }
    .career-detail .career-banner{
        padding: 20px 15px;
    }
    .career-detail .career-banner .sec-bottom h2, .career-detail .career-banner .sec-bottom p{
        text-align: left;
    }
    .career-detail .career-banner .button-sec{
        margin: 10px 0;
    }
    .career-detail .career-banner .button-sec button{
        margin: 10px 15px 15px 0;
    }
    .career-detail .oppenings .job .requirements p{
        white-space: nowrap;
        padding: 0 6px;
    }
    .career-detail .oppenings .job h2{
        font-size: 28px !important;
    }
    .career-detail .contact-form{
        padding: 0px;
        padding-right: 15px;
        margin: 0px;
    }
    .career-detail .contact-form .col-md-6, .career-detail .contact-form  .col-md-12{
        padding-left: 0;
    }
    .career-detail .contact-form form .file-upload .drag .apply-btn{
        display: none;
    }
    .career-detail .contact-form form .file-upload .drag{
        justify-content: center;
        top: 15px;
    }
    .career-detail .contact-form .apply-here-mobile{
        display: block;
      }
      .career-detail .contact-form form{
        align-items: baseline;
      }
      .technologies .contactform, .technologies .framework-tools,
      .technologies .backend-tools {
        padding: 15px;
    }
    .technologies .contactform .submit-btn{
        width: 100%;
    }
    .technologies .framework-tools .tools-list, .technologies .backend-tools .tools-list{
        padding: 0;
    }
    .home-page .home-banner .overlay,
    .home-page .home-services,
    .home-page .home-commitment,
    .home-page .home-commit,
    .home-page .home-commitment .commmit-box,
    .home-page .home-portfolio{
        padding: 15px;
    }
    .home-page .home-banner .sec-bottom{
        padding: 0;
    }
    .home-page .home-banner .sec-bottom h2{
        font-size: 50px;
        line-height: 62px;
    }
    .home-page .home-about-us .right-sec{
        background-size: 214px;
        background-position: center;
        height: 250px;
    }
    .home-page .home-services .sevice-left .pl-2{
        padding-left: 15px !important;
    }
    .home-page .home-services .sevice-left .pr-2{
        padding-right: 15px !important;
    }
    .home-page .home-services .sevice-left .service-list,
    .home-page .home-services .service-box{
        margin-top: 15px !important;
    }
    .home-page .home-services .sevice-left .pt-3{
        padding-top: 0px !important;
    }
    .home-page .home-services .sevice-left .pt-2{
        padding-top: 0px !important;
        margin-top: 0 !important;
    }
    .home-page .home-services .home-service{
        margin-left: 0 !important;
    }
    .home-page .home-portfolio .sec-bottom p,
    .home-page .home-portfolio .sec-bottom h2,
    .home-page .home-portfolio .portfolio-desc p{
        text-align: left;
    }
    .home-page .home-portfolio .portfolio-desc p{
        margin: 0;
    }
    .home-page .home-portfolio .carousel{
        padding: 0;
    }
    .home-page .home-support .service-desc p{
        padding-right: 0;
    }
    .home-page .home-support .sevice-left{
        margin-top: 15px !important;
    }
    .home-page .home-support .sevice-left .service-box .col-md-6,
    .home-page .home-support .sevice-left .service-list{
        margin-top: 0 !important;
    }
    .home-page .home-support .sevice-left .service-box
    {
        height: auto !important;
    }
    .home-page .home-support .sevice-left .service-list{
        width: 100%;
        margin-bottom: 20px;
    }
    .home-page .home-clients .tools-list .tool-box{
        height: 80px;
    }
    .home-page .home-portfolio .portfolio-slider{
        padding: 20px 0;
    }
    .home-page .home-portfolio .react-multi-carousel-list .one-side{
        margin: 0;
    }
    .portfolio-page .works-list{
        padding: 15px;
    }
    .portfolio-page .works-list.detail .left-folio img {
        height: 200px;
    }
    .portfolio-page .works-list .works-item img{
        height: auto !important;
    }
    .portfolio-page #contactform{
        padding: 15px;
    }
    .portfolio-page .our-works .sec-bottom h2{
        font-size: 42px;
        line-height: 56px;
    }
    .portfolio-page .works-list.detail .react-multi-carousel-dot-list{
        display: none;
    }
    .Services .business-industry .sec-bottom p{
        padding: 0;
    }
    .Services .business-industry{
        padding: 15px;
    }
    .Services .business-industry .sec-bottom h2{
        text-align: left;
        font-size: 32px;
        line-height: 44px;
    }
    .Services .business-industry .sec-bottom p{
        text-align: left;
    }
    .Services .business-industry .sec-bottom{
        align-items: baseline;
    }
    .contact-us .form-section .contact-form .submit-btn{
        width: 100%;
    }
    .portfolio-page .form-section .contact-form .submit-btn{
        width: 100%;
    }
    .Services .form-section .contact-form .submit-btn{
        width: 100%;
    }
    .site-pages .sec-bottom .sub-title{
        font-size: 35px;
        line-height: 40px;
    }
    .Services .our-services, .Services #contactform{
        padding: 15px;
    }
    .Services .our-services .pr-0{
        padding-right: 15px !important;
    }
    .site-pages.port-tech .sec-bottom{
        bottom: 15px;
        margin: 10px 15px !important;
    }
    .contact-us .locations .px-2{
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}

@media (min-width: 600px) and (max-width: 991px) {
    

    .user-menu {
        display: none !important;
    }

    .header-bar {
        height: 61px !important;
    }

    .logo {
        width: 90px;
        height: 61px !important;
    }

    .background .headerWrapper {
        height: 61px;
    }

    .header-inner-wrapper {
        height: 61px;
        width: 100%;
        display: flex !important;
    }

    .header-inner-wrapper .logo-wrapper {
        margin-left: unset !important;
        align-items: center;
        margin-top: 0 !important;
    }

    .logo-wrapper {
        margin-top: -40px;
        /* width: 100px; */
        margin-left: auto;
        margin-right: auto;
    }

    #menuToggle {
        display: block;
        /* width: 300px; */
        position: absolute;
        right: 13px;
        z-index: 1;
        padding-top: 28px;
        -webkit-user-select: none;
        user-select: none;
    }

    .sidebar {
        margin-top: 15px;
    }

    #menuToggle a {
        text-decoration: none;
        color: white !important;
        transition: color 0.3s ease;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    #menuToggle a img{
        height: 14px;
    }

    #menuToggle a:hover {
        color: #f4ce24;
    }

    #menuToggle input {
        display: block;
        width: 40px;
        height: 32px;
        position: absolute;
        top: -7px;
        left: -5px;
        cursor: pointer;
        opacity: 0;
        z-index: 2;
        -webkit-touch-callout: none;
    }

    #menuToggle span {
        display: block;
        width: 25px;
        height: 3px;
        margin-bottom: 5px;
        position: relative;
        background: #000000;
        border-radius: 3px;
        z-index: 1;
        transform-origin: 4px 0px;
        transition: cubic-bezier(0.77, 0.2, 0.05, 1),
            background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
    }

    #menuToggle span:first-child {
        transform-origin: 0% 0%;
    }

    #menuToggle span:nth-last-child(2) {
        transform-origin: 0% 100%;
    }

    #menuToggle input:checked~span {
        opacity: 1;
        transform: rotate(45deg) translate(-1px, -4px);
        background: white;
    }

    #menuToggle input:checked~span:nth-last-child(2) {
        transform: rotate(-45deg) translate(0, 4px);
    }

    #menu {
        position: absolute;
        right: -13px;
        width: 300px;
        min-height: 100%;
        height: 4000px;
        margin: -101px 0 0 0px;
        padding: 35px;
        padding-top: 125px;
        background: #26477F;
        list-style-type: none;
        -webkit-font-smoothing: antialiased;
        transform-origin: 0% 0%;
        transform: translate(100%, 0);
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    }

    #menu li {
        padding: 18px 0;
        font-size: 24px;
        color: white;
        width: 250px;
    }

    #menuToggle input:checked~ul {
        transform: none;
    }

    .searchMobile {
        width: 20px;
        position: relative;
        margin-left: auto;
        margin-top: -75px;
        display: block;
        justify-content: flex-end;
    }

    .sideNav {
        width: 240px;
        z-index: inherit;
    }

    .active li {
        color: #43c2ec !important;
    }

    .search-container {
        display: none;
    }

    .searchToggle {
        width: 20px;
        position: relative;
        margin-left: auto;
        margin-top: -75px;
        display: block;
        justify-content: flex-end;
        background-color: transparent;
        outline: 0;
        border: 0;
    }

    .searchiconmobile {
        fill: white;
    }

    .searchiconmobile:hover {
        fill: #f4ce24;
    }
    .home-page .home-services,
    .home-page .home-commitment,
    .home-page .home-commit,
    .home-page .home-commitment .commmit-box,
    .home-page .home-portfolio{
        padding: 15px;
    }
    .home-page .home-banner .sec-bottom{
        padding: 0;
    }
    .home-page .home-banner .sec-bottom h2{
        font-size: 50px;
        line-height: 62px;
    }
    .home-page .home-about-us .right-sec{
        background-size: 214px;
        background-position: center;
        height: 250px;
    }
    .home-page .home-services .sevice-left .pl-2{
        padding-left: 15px !important;
    }
    .home-page .home-services .sevice-left .pr-1{
        padding-right: 15px !important;
    }
    .home-page .home-services .sevice-left .service-list{
        margin-top: 15px !important;
    }
    .home-page .home-services .sevice-left .pt-2{
        padding-top: 0px !important;
        margin-top: 0 !important;
    }
    .home-page .home-services .home-service{
        margin-left: 0 !important;
    }
    .home-page .home-portfolio .sec-bottom p,
    .home-page .home-portfolio .sec-bottom h2,
    .home-page .home-portfolio .portfolio-desc p{
        text-align: left;
    }
    .home-page .home-portfolio .portfolio-desc p{
        margin: 0;
    }
    .home-page .home-portfolio .carousel{
        padding: 0;
    }
    .home-page .home-support .service-desc p{
        padding-right: 0;
    }
    .home-page .home-support .sevice-left{
        margin-top: 15px !important;
    }
    .home-page .home-support .sevice-left .service-box .col-md-6,
    .home-page .home-support .sevice-left .service-list{
        margin-top: 0 !important;
    }
    .home-page .home-support .sevice-left .service-box
    {
        height: auto !important;
    }
    .home-page .home-services .service-box{
        margin-top: 15px !important;
    }
    .home-page .home-services .sevice-left .pr-2{
        padding-right: 15px !important;
    }
    .home-page .home-services .sevice-left .pt-3{
        padding-top: 0px !important;
    }
    .home-page .home-support .sevice-left .service-list{
        width: 100%;
        margin-bottom: 20px;
    }
    .home-page .home-clients .tools-list .tool-box{
        height: 80px;
    }
    .home-page .home-portfolio .portfolio-slider{
        padding: 20px 0;
    }
    .home-page .portfolio-slider .react-multi-carousel-item{
        margin-right: 10px;
    }
    .home-page .home-portfolio .react-multi-carousel-list .one-side{
        margin: 0;
    }
    .about-us .about-teams .sec-bottom p{
        padding-right: 0;
    }
    .about-us .about-teams .person-box img{
        height: auto !important;
    }
    .about-us .designations.mobile-view{
        display: block;
    }
    .about-us .designations:not(.mobile-view){
        display: none;
    }
    .about-us .about-teams .designations.mobile-view .designation-descript{
        display: block !important;
    }
    .about-us .about-teams .designations .designation-descript .total-employee{
        margin: 80px 0;
        padding: 0;
        justify-content: flex-start;
    }
    .about-us .about-teams .designations.mobile-view .nav-item .nav-link.active{
        color: #000000 !important;
    }
    .about-us .about-teams .designations.mobile-view .nav-item .nav-link.nav-link.active svg path {
        d: path('m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z');
    }
    .about-us .inspiring{
        height: auto !important;
    }
    .about-us .inspiring h2{
        font-size: 22px;
        bottom: 0px;
        padding: 0px 0 0 20px;
        line-height: 28px;
    }
    .about-us .inspiring h2 span{
        font-size: 18px;
    }
    .about-us .about-teams .people.mobile-view{
        display: block;
    }
    .about-us .about-teams .people.mobile-view .react-multi-carousel-item{
        margin: 0 8px;
    }
    .about-us .about-teams .people.mobile-view .react-multi-carousel-item:first-child{
        margin-left: 0 !important;
    }
    .about-us .about-teams .people:not(.mobile-view){
        display: none;
    }
    .about-us .vision-mission, .about-us .about-teams{
        padding: 15px;
    }
    .about-us .excellence .news-events .sec-top-title, .about-us .excellence .news-events .sec-bottom .sec-title {
        text-align: left;
    }
    .about-us .excellence .qualities .quality-inner{
        height: auto;
        overflow: hidden;
    }
    .about-us .excellence .news-events {
        margin-top: 50px;
    }
    .about-us .excellence .news-events .p-5{
        padding: 15px !important;
    }
    .portfolio-page .works-list{
        padding: 15px;
    }
    .portfolio-page .works-list.detail .left-folio img {
        height: 200px;
    }
    .Services .business-industry .sec-bottom p{
        padding: 0;
    }
}

@media (min-width: 992px) and (max-width: 1160px) {
    .header-right {
        margin-left: 400px !important;
    }

    .header-bar .nav-bar .parent {
        right: 0 !important;
    }

    .header-bar .logo-wrapper {
        margin-left: 15px;
    }

    .header-bar .logo-wrapper img {
        max-height: 44px !important;
    }

    .header-bar .nav-bar {
        margin: 0 0 !important;
    }
    .carousel-inner>.active, .carousel-inner>.next, .carousel-inner>.prev {
        display: block;
    }
    .portfolio-page .works-list{
        padding: 15px;
    }
    .portfolio-page .works-list.detail .left-folio img {
        height: 200px;
    }
    .home-page .home-services,
    .home-page .home-commitment,
    .home-page .home-commit,
    .home-page .home-commitment .commmit-box,
    .home-page .home-portfolio{
        padding: 15px;
    }
    .home-page .home-banner .sec-bottom{
        padding: 0;
    }
    .home-page .home-banner .sec-bottom h2{
        font-size: 50px;
        line-height: 62px;
    }
    .home-page .home-about-us .right-sec{
        background-size: 214px;
        background-position: center;
    }
    .home-page .home-services .sevice-left .pl-2{
        padding-left: 15px !important;
    }
    .home-page .home-services .sevice-left .pr-1{
        padding-right: 15px !important;
    }
    .home-page .home-services .sevice-left .service-list{
        margin-top: 15px !important;
    }
    .home-page .home-services .sevice-left .pt-2{
        padding-top: 0px !important;
        margin-top: 0 !important;
    }
    .home-page .home-services .home-service{
        margin-left: 0 !important;
    }
    .home-page .home-portfolio .sec-bottom p,
    .home-page .home-portfolio .sec-bottom h2,
    .home-page .home-portfolio .portfolio-desc p{
        text-align: left;
    }
    .home-page .home-portfolio .portfolio-desc p{
        margin: 0;
    }
    .home-page .home-portfolio .carousel{
        padding: 0;
    }
    .home-page .home-support .service-desc p{
        padding-right: 0;
    }
    .home-page .home-support .sevice-left{
        margin-top: 15px !important;
    }
    .home-page .home-support .sevice-left .service-box .col-md-6,
    .home-page .home-support .sevice-left .service-list{
        margin-top: 0 !important;
    }
    .home-page .home-support .sevice-left .service-list{
        width: 100%;
        margin-bottom: 20px;
    }
    .home-page .home-clients .tools-list .tool-box{
        height: 80px;
    }
    .home-page .home-portfolio .portfolio-slider{
        padding: 20px 0;
    }
    .home-page .home-portfolio .react-multi-carousel-list .one-side{
        margin: 0;
    }
    .home-page .portfolio-slider .react-multi-carousel-item{
        margin-right: 10px;
    }
    .home-page .home-services .sevice-left .service-list h2{
        white-space: break-spaces;
    }
    
}

@media (max-width: 800px) {

    .mobile-menutitle {
        display: block !important;
    }

    .header-bar .header-inner-wrapper .logo-wrapper img {
        width: 225% !important;
    }
    .footer-sec .footer-wrapper .toplink{
        display: block !important;
    }
    .footer-sec .footer-wrapper .logo-wrapper{
        display: block;
        margin: 20px 0 !important;
    }
    .footer-wrapper .bottom-footer .mobile-footer {
        display: block;
    }
    .footer-sec .footer-wrapper .footerlink{
        margin-left: 6px !important;
    }

    .mobile-footer .logo-wrapper {
        margin-left: 0;
    }

    .footer-sec .footer-wrapper .footer-desc {
        display: unset !important;
    }

    .footer-sec .regionnav {
        margin-top: 15px;
        flex-direction: row;
        display: flex !important;
        align-items: center;
        justify-content: center;
    }

    .footer-sec .regionnav .regionlink {
        margin: 0 !important;
        display: none;
    }

    .footer-sec .regionnav .regionlink.copyright {
        display: block;
        width: 100%;
        text-align: center;
    }
    .below-footer .followlink{
        margin-top: 10px;
    }
    .footer-sec .regionnav .regionlink.copyright span {
        font-size: 15px;
    }

}
@media (max-width: 991px) {
    .navbar-wrapper {
        display: none;
    }
    header .navbar-wrapper .container{
        width: 100% !important;
        max-width: 100% !important;
    }
}
@media (min-width: 992px) {
    header .navbar-wrapper .container, .footer-sec .container{
        max-width: 90% !important;
        width: 90% !important;
      }
}
/* odoo */
@media (min-width: 320px) and (max-width: 991px) {
    #odooSection #odooPartnershipSection p {
        padding: 15px;
    }

    #odooSection #odooBusinessSection p {
        padding: 15px;
    }
    #odooPartnerHighlightsection {
    padding-left: 15px;
        padding-right: 15px;
    }
        }
/* odoo */